import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "send"
    }}>{`Send`}</h2>
    <p>{`To keep it as simple as possible we accept a GET request.`}</p>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`You need to send your HTTP requests to `}<a parentName="p" {...{
        "href": "https://api.anymessage.cloud/sms/initiate"
      }}>{`https://api.anymessage.cloud/sms/initiate`}</a></p>
    <h3 {...{
      "id": "get-parameters"
    }}>{`GET parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mandatory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`sample value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`username`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`anyDir001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hz$o932`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sender of the sms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`test`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`recipient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sms recipient. Provided in international format without leading + or 00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`491520123456789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message of the SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is a test SMS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dlr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set it to 1 if you want to receive a DLR. Note you need to provide a URL to receive the DLRs first. If you don't want DLrs set it to 0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "encoding"
    }}>{`Encoding`}</h3>
    <p>{`The request (and request parameters) needs to be in UTF-8 and url encoded.`}</p>
    <h3 {...{
      "id": "emoticons--emoji"
    }}>{`Emoticons / Emoji`}</h3>
    <p>{`To send Emoticons like 😁😇 you need to make sure to send them as UTF-8 and
urlencoded request. Do not use UTF-16 or other encoding. (This rule is valid
for all other characters but especially for emotions).`}</p>
    <p>{`See the following Table with valid and invalid examples.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Character`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`UTF-8 hex representation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`valid url encoded string`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`UTF‑16 LE hex representation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`invalid url encoded string`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`😁`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F0 9F 98 81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`%F0%9F%98%81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3D D8 01 DE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`%3D%D8%07%DE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`😇`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F0 9F 98 87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`%F0%9F%98%87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3D D8 07 DE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`%3D%D8%07%DE`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`NOTE:`}</strong>{` Depending on the device it may come to problems to display the Emoticon. For example old devices may replace the Emoticon with ?? or 𑑛.`}</p>
    <h3 {...{
      "id": "features"
    }}>{`Features`}</h3>
    <h4 {...{
      "id": "destination"
    }}>{`Destination`}</h4>
    <p>{`We perform a few modifications on sms destination to make sure the sms is delivered correct and undo typical human errors.`}</p>
    <ol>
      <li parentName="ol">{`We remove leading "+" or "00" from destination. Example +4915201234567 becomes 4915201234567.`}</li>
      <li parentName="ol">{`We remove one following 0 after the country code. Example 49015201234567 becomes 4915201234567.`}</li>
    </ol>
    <h3 {...{
      "id": "request-examples"
    }}>{`Request examples`}</h3>
    <p>{`The following examples fulfill this scenario: `}</p>
    <ol>
      <li parentName="ol">{`You want to send a SMS to your german friend. His msisdn equals 0049 1520 12345678`}</li>
      <li parentName="ol">{`You want your name as SMS source address. Your name is John`}</li>
      <li parentName="ol">{`The SMS should contain the following message "Hi, I hope you are all fine. Wish you all the best John."`}</li>
      <li parentName="ol">{`You don't want to receive an DLR`}</li>
      <li parentName="ol">{`AnyMessage provided you the following credentials username = anyDir001 and password = hz7r§j`}</li>
    </ol>
    <h4 {...{
      "id": "shell"
    }}>{`Shell`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
curl -G -i \\
    --data-urlencode "username=anyDir001" \\
    --data-urlencode "password=hz7r§j" \\
    --data-urlencode "sender=John" \\
    --data-urlencode "recipient=49152012345678" \\
    --data-urlencode "content=Hi, I hope you are all fine. Wish you all the best John." \\
    https://api.anymessage.cloud/sms/initiate
`}</code></pre>
    <p>{`Please note`}</p>
    <ul>
      <li parentName="ul">{`The recipient is 49152012345678. The leading 00 is removed`}</li>
    </ul>
    <h4 {...{
      "id": "php"
    }}>{`PHP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php

$url = 'https://api.anymessage.cloud/sms/initiate?';

$username = 'anyDir001'; 
$password = 'hz7r§j'; 
$sender = 'John'; 
$recipient = '49152012345678'; // NOTE the leading 00 of recipient is truncated
$content = 'Hi, I hope you are all fine. Wish you all the best John.';   


$parameter = array(
    'username'=>$username,
    'password'=>$password,
    'sender'=>$sender,
    'recipient'=>$recipient,
    'content'=>$content,
);

$url .= http_build_query($parameter);
    

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);

$response = curl_exec($ch);

// Response evaluation 
if($response === false){
    var_dump(curl_error($ch));
}else{
    var_dump($response);
}
?>
`}</code></pre>
    <h3 {...{
      "id": "request-responses"
    }}>{`Request responses`}</h3>
    <h4 {...{
      "id": "on-success"
    }}>{`On success`}</h4>
    <p>{`You Receive a HTTP status code of 200 and a json formatted message containing a message Id.`}</p>
    <p>{`A sample success response looks like this.`}</p>
    <pre><code parentName="pre" {...{}}>{`> {"msgId":"9325d0a8-2638-11e6-afe7-bffc7cc8fa4f"}
`}</code></pre>
    <h4 {...{
      "id": "on-failure"
    }}>{`On failure`}</h4>
    <pre><code parentName="pre" {...{}}>{`| HTTP status Code | Http response                                    | description                                              |
| ---------------- | ------------------------------------------------ | -------------------------------------------------------- |
| 400              | {"message":"Not all params specified!"}          | The request does not contain all mandatory GET parameter |
| 400              | {"message":"recipient needs to be numeric only"} | The provided recipient contains non digit characters     |
| 401              | {"message":"Invalid credentials"}                | The provided credentials are not valid                   |
| 403              | {"message":"Action forbidden"}                   | Your account is not authorized to use this endpoint      |
| 500              | {"message":"Internal Error"}                     | An internal error occurred                               |
`}</code></pre>
    <h2 {...{
      "id": "dlr"
    }}>{`DLR`}</h2>
    <h3 {...{
      "id": "introduction"
    }}>{`Introduction`}</h3>
    <p>{`After you send a message and requested a DLR (set dlr=1) via our API you will
receive status updates regarding this message. These updates are sent to your
system via http / https. `}</p>
    <p>{`In order to receive DLRs our support team has to add your desired http endpoint
(push url) to your account. If you don't have a configured http endpoint please
contact our support at  `}<a parentName="p" {...{
        "href": "mailto:support@anymessage.cloud?subject=DLR%20URL%20configuration&body=Dear%20Support%20Team,%0D%0A%0D%0AI%20would%20like%20receive%20DLRs%20for%20the%20following%20Accounts:%20%0D%0A%0D%0AACCOUNT_NAME_1.%20Please%20send%20DLRs%20to%20the%20following%20url:%20MY_HTTP_URL_FOR_ACCOUNT_1%0D%0AACCOUNT_NAME_2.%20Please%20send%20DLRs%20to%20the%20following%20url:%20MY_HTTP_URL_FOR_ACCOUNT_2%0D%0A%0D%0AThanks%20in%20advance%20and%20have%20a%20nice%20day.%0D%0AYOUR_NAME"
      }}>{`support@anymessage.cloud`}</a>{`.`}</p>
    <h3 {...{
      "id": "dlr-parameters"
    }}>{`DLR parameters`}</h3>
    <p>{`The DLRs are send via http as GET Request to your system. A DLR consists always
out of the following parameters. Depending on the message type special
parameters may added.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`value type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`message_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`small/ big letters, numbers, characters out of ("-","_")`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3c86ffff-40b2-ca36-807d-560cd583a7ca`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique id to identify your message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`submit_date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`date in format "YYYY-MM-DD hh:mm"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-10-01 08:42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of accepting your message in UTC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`done_date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`date in format "YYYY-MM-DD hh:mm"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-10-01 08:45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of delivering your message in UTC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`status_code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A number to identify the status of the message see `}<a parentName="td" {...{
              "href": "#status-code-values"
            }}>{`Status Code Values`}</a>{` for details`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`small/ big letters, numbers, whitespace`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`delivered  or expired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Human readable status description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A number to identify the error of the message see `}<a parentName="td" {...{
              "href": "#error-code-values"
            }}>{`Error Code Values`}</a>{` for details`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`small/ big letters, numbers, whitespace`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Human readable error description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`small/ big letters, numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of message type`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "special-sms-parameters"
    }}>{`Special SMS parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`value type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`submitted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`three digit number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number of sms parts submitted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`delivered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`three digit number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number of sms parts delivered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`added in addition to SMPP protocol can be safely ignored`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "status-code-values"
    }}>{`Status Code Values`}</h3>
    <p>{`Each status code represent a status of yor message. You can either use the value of the corresponding status field or the following table to understand the codes. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`status_code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`final`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`scheduled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message is scheduled to send`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enroute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message has left our system or downstream carrier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`delivered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message is arrived on recipients device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`expired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`message could not be send to the recipient. normally within 48 h`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`message is deleted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message is deleted and will not be send`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`undelivered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message could not sent to recipient`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message is accepted from our system or downstream carrier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`unknown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the status of the message is unknown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`rejected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the message is rejected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`skipped`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message was skipped`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "error-code-values"
    }}>{`Error Code Values`}</h3>
    <p>{`Each error code represent a error that mya occur on yor message. You can either use the value of the corresponding error field or the following table to understand the codes. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`error_code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`error`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no error is occurred`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`unknown error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a unknown error is occurred`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      